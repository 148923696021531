import React from "react"
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import Card from '../components/card'
import Header from '../components/header'
import { FlexBlogContainer, PostContainer, TagHeader } from '../styles/blog'

// Components
import { graphql } from "gatsby"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } sobre "${tag}"`

  return (
    <Layout>
      <Header 
        location={location}
        borderRadius={true}
      />
      <FlexBlogContainer>
        <PostContainer style={{marginTop:"2.8rem"}}>
          <TagHeader>
            <h1>{tagHeader}</h1>
          </TagHeader>
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title, description, tags } = node.frontmatter
            const { publicURL } = node.frontmatter.thumbnail

            return (
              <Card 
                title={title}
                description={description}
                slug={slug}
                thumbnail={publicURL}
                tags={tags}
              />
            )
          })}
        </PostContainer>
      </FlexBlogContainer>
    </Layout>
  )
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
      tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        totalCount: PropTypes.number.isRequired,
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
              }),
              fields: PropTypes.shape({
                slug: PropTypes.string.isRequired,
              }),
            }),
          }).isRequired
        ),
      }),
    }),
  }
export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            description
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`